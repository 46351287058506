import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {RegexUtils} from '../../../core/utils/regex-utils';

@Component({
  selector: 'app-control-messages',
  template: `<div class="isInvalid" *ngIf="errorMessage !== null" [innerHTML]="errorMessage"></div>`
})
export class ControlMessagesComponent {
  @Input() control: AbstractControl;
  @Input() override: string;

  getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
    const ERROR_MESSAGES = {
      required: 'This field is required',
      matchingFullName: 'First name and last name cannot be both empty',
      paxNameMatching: 'You are entering the same name matching method twice',
      pattern_title_multi: 'Comma separated list of passenger titles/suffixes (2 letters minimum)',
      pattern_queue: 'Office/Queue Number and Optional Category (C#) i.e. NCE7X0124/36 or NCE7X0134/34C8',
      pattern_alt_queue: ' Please provide a queue and category. For example, 1C7',
      pattern_sk_type: 'Please provide a 4-character SK keyword',
      pattern_sk_type_multi: 'SK codes are 4 letters, separated by commas',
      pattern_sk_message_type: 'Please provide the SK message type which should be 2-4 capital letters',
      pattern_airline_accounting_code_multi: 'Please provide a comma separated list of 3 digit ticket stock numbers',
      pattern_cid: 'Corporate ID codes are 3 characters, separated by commas',
      pattern_3_offices: 'Up to 3 Authorized Offices (only uppercase alpha-numeric and * chars are allowed), separated by commas',
      pattern_class_hierarchy: 'Please provide single character classes, separated by commas',
      pattern_currency_code: 'Currency code can only have 3 characters',
      pattern_rfic_code: 'RFIC Codes are single character codes,seperated by commas',
      pattern_rfisc_code: 'Three character alphanumeric values supported,seperated by commas',
      pattern_range:'Number should be in 0-100 format',
      pattern_string_custom_pattern: 'Alphabetic character and "?" allowed only',
      pattern_single_name: 'Only alphabetic characters, dash and space are allowed',
      pattern_full_name: 'Only alphabetic characters, dash and space are allowed in the format of "Last name/First name"',
      pattern_carrier_code: 'Please provide a 2-digit carrier code',
      pattern_carrier_code_multi: 'Please provide a comma separated list of 2 digit carrier codes for your co-hosted carriers',
      pattern_country_code_multi: 'Please provide domestic country codes with two letters, separated by comma',
      pattern_city_code_3: 'Please enter the three-character base city code',
      pattern_log_location: 'Please provide a valid UNIX or DOS location',
      pattern_email_multi: 'Please provide valid email addresses, separated by a comma',
      pattern_uppercase_string: 'Please provide a string without any lowercase char',
      pattern_free_text: 'Only uppercase alpha-numeric chars, spaces and any of !%*:_,=()/[] are allowed',
      pattern_free_text_multi: 'Only uppercase alpha-numeric chars, spaces and any of !%*:_,=()/[] are allowed',
      pattern_additional_ssr_message: 'Only uppercase alpha-numeric chars, spaces, and any of .-/ are allowed',
      pattern_gtl_compliance_time_limit_type: 'Only one of AGT|DEP|PAY|NME|TKT is allowed',
      pattern_gtl_compliance_time_limit_code: 'Only uppercase alpha-numeric chars, spaces, line breaks and any of !%*:_,=()/[] are allowed',
      pattern_gtl_compliance_waiver: 'Should start with [Carrier code] then one of AGREEMENT|DEPOSIT|FINAL PAYMENT|NAME|TTL and end up with WAIVED',
      pattern_gtl_compliance_waiver_code: 'Only one of AGREEMENT|DEPOSIT|FINAL PAYMENT|NAME|TTL is allowed',
      pattern_waitlist_status_code_multi: 'Only the following status codes are allowed (AG, CN, CQ, CX, FF, FG, FR, GI, GK, GL, GN, HN, HX, ID, IG, IN, IX, NA, NN, NO, NZ, OX, PN, PX, RG, SA, SG, ST, TN, UC, UM, UN, XL, XR, XX), separated by comma',
      pattern_all_waitlist_status_code_multi: 'Only the following status codes are allowed (AG, AW, CL, CN, CQ, CU, CW, CX, DW, FF, FG, FR, GI, GK, GL, GN, HL, HN, HX, ID, IG, IN, IW, IX, LF, LG, LL, NA, NN, NO, NZ, OX, PA, PB, PC, PD, PE, PG, PN, PX, RG, SA, SG, ST, TL, TN, UC, UM, UN, US, UU, XL, XR, XX), separated by comma',
      pattern: `Should match the following pattern ${validatorValue.requiredPattern}`,
      minArrayLength: `Minimum ${validatorValue.requiredLength} items required`,
      maxArrayLength: `Maximum ${validatorValue.requiredLength} items required`,
      minlength: `Minimum length is ${validatorValue.requiredLength} chars`,
      maxlength: `Maximum length is ${validatorValue.requiredLength} chars`,
      min: `Minimum is ${validatorValue.min}`,
      max: `Maximum is ${validatorValue.max}`,
      duplicateTitle:'Duplicate Titles Not allowed'
    };

    if (isNotNullOrUndefined(this.override)) {
      return this.override;
    }

    if (validatorName === 'pattern') {
      switch (validatorValue.requiredPattern) {
        case RegexUtils.VALIDATOR_QUEUE.toString():
          return ERROR_MESSAGES.pattern_queue;
        case RegexUtils.VALIDATOR_ALT_QUEUE.toString():
          return ERROR_MESSAGES.pattern_alt_queue;
        case RegexUtils.VALIDATOR_TOLERANCE_PERCENTAGE1.toString():
          return ERROR_MESSAGES.pattern_range;
        case RegexUtils.VALIDATOR_TOLERANCE_PERCENTAGE2.toString():
          return ERROR_MESSAGES.pattern_range;
        case RegexUtils.VALIDATOR_DEFAULT_CURRENCY.toString():
          return ERROR_MESSAGES.pattern_currency_code;
        case RegexUtils.VALIDATOR_RFICCODE_DEPOSIT.toString():
          return ERROR_MESSAGES.pattern_rfic_code;
        case RegexUtils.VALIDATOR_RFISCCODE_DEPOSIT.toString():
          return ERROR_MESSAGES.pattern_rfisc_code;
        case RegexUtils.VALIDATOR_PASSENGER_TITLE_MULTI.toString():
          return ERROR_MESSAGES.pattern_title_multi;
        case RegexUtils.VALIDATOR_SK_TYPE.toString():
          return ERROR_MESSAGES.pattern_sk_type;
        case RegexUtils.VALIDATOR_SK_TYPE_MULTI.toString():
          return ERROR_MESSAGES.pattern_sk_type_multi;
        case RegexUtils.VALIDATOR_SK_MESSAGE_TYPE.toString():
          return ERROR_MESSAGES.pattern_sk_message_type;
        case RegexUtils.VALIDATOR_AIRLINE_ACCOUNTING_CODE_MULTI.toString():
          return ERROR_MESSAGES.pattern_airline_accounting_code_multi;
        case RegexUtils.VALIDATOR_CID.toString():
          return ERROR_MESSAGES.pattern_cid;
        case RegexUtils.VALIDATOR_UPTO_3_OFFICES.toString():
          return ERROR_MESSAGES.pattern_3_offices;
        case RegexUtils.VALIDATOR_CLASS_HIERARCHY.toString():
          return ERROR_MESSAGES.pattern_class_hierarchy;
        case RegexUtils.VALIDATOR_STRING_CUSTOM_PATTERN.toString():
          return ERROR_MESSAGES.pattern_string_custom_pattern;
        case RegexUtils.VALIDATOR_SINGLE_NAME.toString():
          return ERROR_MESSAGES.pattern_single_name;
        case RegexUtils.VALIDATOR_FULL_NAME.toString():
          return ERROR_MESSAGES.pattern_full_name;
        case RegexUtils.VALIDATOR_CARRIER_CODE.toString():
          return ERROR_MESSAGES.pattern_carrier_code;
        case RegexUtils.VALIDATOR_CARRIER_CODE_MULTI.toString():
          return ERROR_MESSAGES.pattern_carrier_code_multi;
        case RegexUtils.VALIDATOR_COUNTRY_CODE_MULTI.toString():
          return ERROR_MESSAGES.pattern_country_code_multi;
        case RegexUtils.VALIDATOR_CITY_CODE_3.toString():
          return ERROR_MESSAGES.pattern_city_code_3;
        case RegexUtils.VALIDATOR_LOG_LOCATION.toString():
          return ERROR_MESSAGES.pattern_log_location;
        case RegexUtils.VALIDATOR_EMAIL_MULTI.toString():
          return ERROR_MESSAGES.pattern_email_multi;
        case RegexUtils.VALIDATOR_UPPERCASE_STRING.toString():
          return ERROR_MESSAGES.pattern_uppercase_string;
        case RegexUtils.VALIDATOR_FREE_TEXT.toString():
          return ERROR_MESSAGES.pattern_free_text;
        case RegexUtils.VALIDATOR_FREE_TEXT_MULTI.toString():
          return ERROR_MESSAGES.pattern_free_text_multi;
        case RegexUtils.VALIDATOR_ADDITIONAL_SSR_MESSAGE.toString():
          return ERROR_MESSAGES.pattern_additional_ssr_message;
        case RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_TYPE.toString():
          return ERROR_MESSAGES.pattern_gtl_compliance_time_limit_type;
        case RegexUtils.VALIDATOR_GTL_COMPLIANCE_TIME_LIMIT_CODE.toString():
          return ERROR_MESSAGES.pattern_gtl_compliance_time_limit_code;
        case RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER.toString():
          return ERROR_MESSAGES.pattern_gtl_compliance_waiver;
        case RegexUtils.VALIDATOR_GTL_COMPLIANCE_WAIVER_CODE.toString():
          return ERROR_MESSAGES.pattern_gtl_compliance_waiver_code;
        case RegexUtils.VALIDATOR_WAITLIST_STATUS_CODE.toString():
          return ERROR_MESSAGES.pattern_waitlist_status_code_multi;
        case RegexUtils.VALIDATOR_ALL_WAITLIST_STATUS_CODE.toString():
          return ERROR_MESSAGES.pattern_all_waitlist_status_code_multi;
      }
    }

    return ERROR_MESSAGES[validatorName];
  }

  constructor() { }

  get errorMessage(): string {
    if (this.control && this.control.errors) {
      for (const propertyName in this.control.errors) {
        if (!this.control.valid && this.control.errors.hasOwnProperty(propertyName)) {
          return this.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
        }
      }
    }
    return null;
  }
}
